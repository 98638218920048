export const WIZARD_STEPS = {
  OPPORTUNITY: 0,
  FORM: 1,
  PAYMENT: 2,
  PREVIEW: 3,
  REGISTRATION: 4,
  COMMITTEES: 5,
  SUBMISSION: 6,
  REVIEW: 7,
  TRACKS: 8,
};

export const EVENT_TYPES = {
  CONFERENCE_MULTI_TRACK: 'conference-multi-track',
  CONFERENCE_SINGLE_TRACK: 'conference-single-track',
  WORKSHOP: 'workshop',
  SYMPOSIUM: 'symposium',
  SEMINAR: 'seminar',
  WEBINAR: 'webinar',
  SUMMER_AUTUMN_WINTER_SCHOOL: 'summer-autumn-winter-school',
  COURSE_AND_TRAINING: 'course-and-training',
  SCIENCE_CAMP: 'science-camp',
  COMPETITIONS: 'competitions',
  SCIENCE_EXHIBITIONS_AND_SCIENCE_FESTIVAL: 'science-festival-and-science-exhibitions-demonstrations',
  PHD_SCHOOL_WITH_REVIEW_PROCESS : 'phd-school-with-review-process',
  PHD_SCHOOL_WITHOUT_REVIEW_PROCESS: 'phd-school-without-review-process',
};

export const SESSION_TYPES = {
  KEYNOTE_SPEAKER: 'keynote_speaker',
  POSTER: 'poster',
  PAPER: 'paper',
};

export const MULTI_TRACKS_WIZARD_STEPS = {
  OPPORTUNITY: 0,
  TRACKS: 1,
  COMMITTEES: 2,
  SUBMISSION: 3,
  REVIEW: 4,
  REGISTRATION: 5,
  PAYMENT: 6,
  // PREVIEW: 7,
};

export const SINGLE_TRACK_WIZARD_STEPS = {
  OPPORTUNITY: 0,
  COMMITTEES: 1,
  SUBMISSION: 2,
  REVIEW: 3,
  REGISTRATION: 4,
  PAYMENT: 5,
  // PREVIEW: 6,
};

export const PHD_SCHOOL_WITHOUT_REVIEW_PROCESS_WIZARD_STEPS = {
  OPPORTUNITY: 0,
  COMMITTEES: 1,
  REGISTRATION: 2,
  PAYMENT: 3,
  // PREVIEW: 4,
};

export const SEMINAR_WIZARD_STEPS = {
  OPPORTUNITY: 0,
  REGISTRATION: 1,
  PAYMENT: 2,
  // PREVIEW: 3,
};

export const COMPETITIONS_WIZARD_STEPS = {
  OPPORTUNITY: 0,
  REGISTRATION: 1,
  REVIEW: 2,
  PAYMENT: 3,
  // PREVIEW: 4,
};

export const IMPORTANT_DATES_TYPES = [
  'Abstract Deadline',
  'Submission Deadline',
  'Registration Deadline',
  'Review Deadline',
  'Acceptance Notification',
  'Camera Ready',
];

export const SUPPORTERS_TYPES = [
  {label: 'Bronze', value: 'BRONZE' },
  {label: 'Silver', value: 'SILVER' },
  {label: 'Gold', value: 'GOLD' },
  {label: 'Platium', value: 'PLATINUM' },
  {label: 'Other', value: 'OTHER' },
];
